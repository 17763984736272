export type ListNode = {
  _id: string
  children: ListNode[]
  parent: string
  // TODO: temporary fix, must be fixed
  name: { [key: string]: string }
  nameSlug: string
}

export default function listToTree<T extends ListNode>(list: T[]): T[] {
  const map: { [key: string]: number } = {}
  const roots: T[] = []

  for (let i = 0; i < list.length; i += 1) {
    if(!list[i].parent) list[i].parent = list[i]._id
    if (list[i].parent == list[i]._id) {
      list[i].parent = null;
    }
    map[list[i]._id] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i]
    if (node.parent !== null) {
      if (!(map[node.parent] in list)) continue
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent]].children.push(node)
    }
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i]
    if (node.parent === null) {
      roots.push(node)
    }
  }
  return roots
}

export const collectCategoryIds = (
  categoryIds: string[],
  selectedCategory: ListNode
): void => {
  categoryIds.push(selectedCategory._id)
  const children = selectedCategory.children || []
  children.forEach((childCategory) =>
    collectCategoryIds(categoryIds, childCategory)
  )
}

export const pushChildren = (_id: string, categoriesData: Category[]) => {
  let result: Category[] = []

  const category = categoriesData.find((x) => x._id === _id)
  if (category) {
    result.push(category)

    for (const child of categoriesData) {
      if (child.parent === _id) {
        const children = pushChildren(child._id, categoriesData)
        result = result.concat(children)
      }
    }
  }

  return result
}