import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import styles from './RecipeProductQuantity.module.scss'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { useRouter } from 'next/router'

type Props = {
  id: string
  onChange: (q) => void;
  stock: number;
  readonly?: boolean
  countStep: number
  quantity?: number
  productQuantityInRecipe: number
}

export default function RecipeProductQuantity(props: Props) {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }



  const shakeRef = useRef<HTMLDivElement>(null)
  const [quantityClassName, setQuantityClassName] = useState<string>()
  useEffect(() => {
    setQuantityClassName(cn({
      [styles.quantity]: true,
      [styles.disable]: props.readonly,
      [styles.increasedQuantity]: !props.readonly && props.quantity > (props.productQuantityInRecipe || 0),
      [styles.decreasedQuantity]: !props.readonly && props.quantity < (props.productQuantityInRecipe || 0),
    }))
  }, [props])

  async function decrease() {
    if (props.readonly) return;
    if (props.quantity - (props.countStep || 1) < 0) return;
    props.onChange(props.quantity - (props.countStep || 1))
  }

  function increase() {
    if (props.readonly) return;
    if (props.stock == 0 || noStock()) return;
    props.onChange(props.quantity + (props.countStep || 1))
  }

  function noStock() {
    return props.stock - (props.countStep || 1) == props.quantity;
  }

  return (
    <div ref={shakeRef} className={styles.quantity_cnt}>
      <span className={quantityClassName} id={props.id}>
        <FiMinus size="22px" color={props.readonly ? '#9CA3AF' : "#007A3E"} onClick={decrease} />
        {props.quantity >= 0 ? <span style={props.readonly ? { color: '#9CA3AF' } : {}}>{Math.round((props.quantity) * 100) / 100}</span> : <></>}
        <FiPlus size="28px" color={props.readonly || noStock() ? '#9CA3AF' : "#007A3E"} onClick={increase}
        />
      </span>
    </div>
  )
}
