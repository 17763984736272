import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'

import RequestHelper from '@utils/RequestHelper'

import ProductModuleCarousel from './ProductModuleCarousel'
import BannerModule from '@components/common/ProductModules/Banner'
import { TProductModule } from '../../../@types/ProductModule'
import BannerSkeleton from '@components/ui/BannerSkeleton/BannerSkeleton'
import { ProductSkeletonSingle } from '@components/ui'
import UserContext from 'contexts/UserContext'

type Props = {
  userId?: string | null | undefined
  selectedAddress?: Address | undefined
}

export default function ProductModule(props: Props) {
  const router = useRouter()
  const firstLoad = useRef(true)

  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const [loading, setLoading] = useState(true)
  const [productModules, setProductModules] = useState<TProductModule[]>([])
  const { selectedAddress } = useContext(UserContext)

  async function getProductModules(selectedAddress) {
    setLoading(true)
    const authorized = Boolean(props.userId)
    let url = 'productModules/full?lang=' + lang

    if (authorized && selectedAddress?.warehouseId) {
      url += `&warehouseId=${selectedAddress?.warehouseId}`
    } else {
      url += '&warehouseId=undefined'
    }

    const { data } = await RequestHelper.catalog.get(url)
    setProductModules(data.data)
    firstLoad.current = false;
    setLoading(false)
  }


  useEffect(() => {
    if (!firstLoad.current) {
      if (lang) {
        getProductModules(selectedAddress)
      }
    }
  }, [lang, selectedAddress?.warehouseId])

  useEffect(() => {
    getProductModules(props.selectedAddress)
  }, [])

  return (
    <>
      {loading ? (
        <>
          <BannerSkeleton />
          <div className="mt-3">
          
            <ProductSkeletonSingle />
          </div>
        </>
      ) : (
        productModules.map((m) =>
          m.type === 'module' ? (
            <ProductModuleCarousel productModule={m} key={m._id} />
          ) : (
            <BannerModule productModule={m} key={m._id} />
          )
        )
      )}
    </>
  )
}
